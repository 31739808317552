<template>
  <BaseHeaderBar fixed>
    <template #left>
      <BaseButton class="mr-3" @click="goBack" icon="pi pi-angle-left" />
    </template>

    <template #center>
      <BaseText class="font-bold">{{ course.title }}</BaseText>
    </template>
  </BaseHeaderBar>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "Header",

  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        title: null,
      }),
    },
  },

  setup() {
    const router = useRouter();

    const goBack = async () => {
      try {
        await router.push({ name: "MyCourses" });
      } catch {
        return;
      }
    };

    return {
      goBack,
    };
  },
};
</script>
