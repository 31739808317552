<template>
  <div class="course-show-sidebar flex text-white px-3 pt-8">
    <div class="block w-18rem">
      <div class="my-4" v-if="shouldRender">
        <BaseCard class="mb-6">
          <slot name="header" />
        </BaseCard>

        <BaseCard>
          <slot />
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseSidebar",
  props: {
    shouldRender: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.course-show-sidebar {
  background-color: var(--gc-main-background-color);

  .p-card-body {
    padding: 0;
  }
}
</style>
