<template>
  <CourseSidebar class="course-show-sidebar__left">
    <template #header>
      <BaseText
        @click="showCourseInfo"
        class="font-bold item-header p-3 cursor-pointer"
        :class="{ 'item-header--active text-primary': currentRoute === 'MyCoursesShow' }"
      >
        {{ $t("uc.my.courses.show.courseInfo") }}
      </BaseText>
    </template>

    <template #default>
      <div v-if="hasDocs" class="items">
        <CourseSidebarItem
          v-for="(chapter, index) in chapters"
          class="items__item p-3 mb-3 cursor-pointer"
          :class="{ 'items__item--active text-primary': activeChapter === chapter.id }"
          :display-name="chapter.displayName"
          :status="chapter.courseChapterProgression.courseChapterProgressionStatus"
          :header="$t('uc.my.courses.show.chapter', { number: index + 1 })"
          :key="chapter.id"
          @click="showChapter(chapter.id)"
        />
      </div>

      <div v-else class="text-center">
        <BaseProgressSpinner size="25" />
      </div>
    </template>
  </CourseSidebar>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import CourseSidebar from "./CourseSidebar";
import CourseSidebarItem from "./CourseSidebarItem";
import { useStore } from "vuex";

export default {
  name: "SidebarLeft",
  components: { CourseSidebarItem, CourseSidebar },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const chapters = ref([]);

    const { courseId } = route.params;
    const currentRoute = ref(route.name);
    const activeChapter = ref(parseInt(route.params.courseChapterId, 10));

    const { docs, hasDocs, callApi } = useApi({
      module: "uc",
      method: "listCourseChapters",
      onMount: true,
      params: { courseId },
      onSuccess: () => {
        chapters.value = docs.value.data;
        store.dispatch("uc/chapters/setChapterList", chapters.value);
      },
    });

    const showChapter = (id) => {
      router.push({
        name: "MyCoursesShowChapter",
        params: { courseChapterId: id },
      });
    };

    const showCourseInfo = () => {
      router.push({
        name: "MyCoursesShow",
        params: {
          courseId,
        },
      });
    };

    const reloadChapterList = computed(() => store.getters["uc/chapters/reloadChapterList"]);

    watch(reloadChapterList, (value) => {
      if (value) {
        callApi();
        store.dispatch("uc/chapters/reloadChapterList", false);
      }
    });

    watch(route, () => {
      currentRoute.value = route.name;
      activeChapter.value = parseInt(route.params.courseChapterId, 10);
    });

    return {
      showCourseInfo,
      showChapter,
      hasDocs,
      chapters,
      currentRoute,
      activeChapter,
    };
  },
};
</script>

<style lang="scss">
.course-show-sidebar__left {
  .item-header {
    display: block;
    background-color: var(--haw-color-item-inactive);

    &--active {
      background-color: var(--haw-color-item-active);
    }
  }

  .items {
    min-height: calc(65vh - 1.5rem);

    &__item {
      background-color: var(--haw-color-item-inactive);

      &--active {
        background-color: var(--haw-color-item-active);
      }
    }
  }
}
</style>
