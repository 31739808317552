<template>
  <CourseSidebar class="course-show-sidebar__right" :should-render="!!courseChapterId">
    <template #header>
      <BaseText
        @click="showChapterInfo"
        class="font-bold item-header p-3 cursor-pointer"
        :class="{ 'item-header--active text-primary': currentRoute === 'MyCoursesShowChapter' }"
      >
        {{ $t("uc.my.courses.show.chapterInfo") }}
      </BaseText>
    </template>

    <template #default>
      <div v-if="chapter" class="items">
        <CourseSidebarItem
          v-for="(chapterItem, index) in chapter.courseChapterItems"
          class="items__item p-3 mb-3 cursor-pointer"
          :class="{ 'items__item--active text-primary': activeItem === chapterItem.id }"
          :display-name="chapterItem.displayName"
          :status="chapterItem.courseChapterItemProgression.courseChapterItemProgressionStatus"
          :header="$t('uc.my.courses.show.chapterItem', { number: index + 1 })"
          :key="chapterItem.id"
          @click="showChapterItem(chapterItem.id)"
        />
      </div>

      <div v-else class="text-center">
        <BaseProgressSpinner size="25" />
      </div>
    </template>
  </CourseSidebar>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import CourseSidebarItem from "./CourseSidebarItem";
import CourseSidebar from "./CourseSidebar";
import { useStore } from "vuex";

export default {
  name: "SidebarRight",
  components: { CourseSidebar, CourseSidebarItem },
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        title: null,
      }),
    },
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const chapter = ref();
    const currentRoute = ref(route.name);
    const activeItem = ref(parseInt(route.params.courseChapterItemId, 10));

    const courseChapterId = computed(() => {
      return route.params.courseChapterId;
    });

    const reload = () => {
      callApi({ courseChapterId: courseChapterId.value });
    };

    watch(courseChapterId, () => {
      if (courseChapterId.value) {
        chapter.value = null;
        reload();
      }
    });

    const { docs, callApi } = useApi({
      module: "uc",
      method: "showCourseChapter",
      params: { courseChapterId: courseChapterId.value },
      onMount: !!courseChapterId.value,
      onSuccess: () => {
        chapter.value = docs.value.data;
        store.dispatch("uc/chapters/setSelectedChapter", chapter.value);
      },
    });

    const showChapterInfo = async () => {
      try {
        await router.push({
          name: "MyCoursesShowChapter",
          params: { courseChapterId: courseChapterId.value },
        });
      } catch {
        return;
      }
    };

    const showChapterItem = async (id) => {
      try {
        await router.push({
          name: "MyCoursesShowChapterItem",
          params: { courseChapterItemId: id },
        });
      } catch {
        return;
      }
    };

    const reloadChapterItemList = computed(
      () => store.getters["uc/chapterItems/reloadChapterItemList"]
    );

    watch(reloadChapterItemList, (value) => {
      if (value) {
        reload();
        store.dispatch("uc/chapterItems/reloadChapterItemList", false);
      }
    });

    watch(route, () => {
      currentRoute.value = route.name;
      activeItem.value = parseInt(route.params.courseChapterItemId, 10);
    });

    return {
      showChapterInfo,
      showChapterItem,
      callApi,
      currentRoute,
      activeItem,
      chapter,
      courseChapterId,
    };
  },
};
</script>

<style lang="scss">
.course-show-sidebar__right {
  .item-header {
    display: block;
    background-color: var(--haw-color-item-inactive);

    &--active {
      background-color: var(--haw-color-item-active);
    }
  }

  .items {
    min-height: calc(65vh - 1.5rem);

    &__item {
      background-color: var(--haw-color-item-inactive);

      &--active {
        background-color: var(--haw-color-item-active);
      }
    }
  }
}
</style>
