<template>
  <div class="course-sidebar-item">
    <BaseText :class="{ 'text-primary': isActive }">
      {{ header }}
    </BaseText>

    <br />

    <BaseText class="font-bold" :class="{ 'text-primary': isActive }">
      {{ displayName }}
    </BaseText>

    <BaseTag v-if="status" fullWidth :severity="status.severity" padding="2" class="mt-3">
      {{ status.displayName }}
    </BaseTag>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false,
      default: null,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayName: {
      type: String,
      required: false,
      default: null,
    },
    status: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.course-sidebar-item {
  word-break: break-word;
}
</style>
