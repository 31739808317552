<template>
  <div class="flex haw-home" v-if="authUserReady && course">
    <Header :course="course" />
    <SidebarLeft :course="course" />

    <MainContent class="pt-8" :paddingRight="false">
      <router-view v-if="!$slots.default" />
    </MainContent>

    <SidebarRight :course="course" />
  </div>
  <div v-else class="w-full py-8 text-center">
    <BaseProgressSpinner />
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { useStore } from "vuex";
import SidebarLeft from "./partials/SidebarLeft.vue";
import Header from "./partials/Header.vue";
import MainContent from "../../../../haw-components-hac/Shared/HAWHome/partials/MainContent.vue";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import SidebarRight from "./partials/SidebarRight";

export default {
  name: "CourseLayout",

  props: {
    public: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    SidebarRight,
    SidebarLeft,
    MainContent,
    Header,
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const user = store.getters["usm/authUser/user"];
    const authUserReady = ref(!!user);

    const { courseId } = route.params;
    const course = ref(null);

    const { callApi, docs } = useApi({
      module: "uc",
      method: "showCourse",
      onMount: authUserReady.value,
      params: { courseId: parseInt(courseId, 10) },
      onSuccess: () => {
        course.value = docs.value.data;
      },
    });

    const loadUser = useApi({
      module: "usm",
      method: "showAuthUser",
      onMount: !authUserReady.value && !props.public,
      onSuccess: async () => {
        await store.dispatch("usm/authUser/saveData", loadUser.docs.value);
        authUserReady.value = true;
        callApi();
      },
      onError: async () => {
        try {
          await router.push({
            name: "Login",
          });
        } catch {
          return;
        }
      },
    });

    return { authUserReady, course };
  },
};
</script>

<style lang="scss" scoped>
.haw-home {
  background-color: #ffffff;
}
</style>
